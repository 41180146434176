import React, { useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CommonDropdownField } from "src/components/common/common-dropdown-field";
import {
  SOURCE_OPTIONS,
  STATUS_OPTIONS,
  LEAD_OWNER_OPTIONS,
  IS_ACTIVE_OPTIONS,
  STAGE_OPTIONS,
} from "src/utils/constants";

const FilterHeader = ({
  styles,
  filterData,
  handleFilterReset,
  handleFilterDataChange,
}) => {
  

  return (
    <Box sx={{ p: 1, backgroundColor: "#F4F6F8", }}>
      <Grid container spacing={2} alignItems="center">
        {/* First Dropdown - Status */}
        <Grid item xs={2.4}>
          <InputLabel sx={{ fontSize: "14px", marginBottom: "5px" }}>
            Status
          </InputLabel>
          <CommonDropdownField
            defaultValue={filterData?.status}
            onChange={handleFilterDataChange}
            // handleChange={handleIsActiveChange}
            // defaultValue={"all"}
            name="status"
            // value={formData?.stage}
            options={STATUS_OPTIONS}
            customClass={"select_btn"}
          />
        </Grid>

        {/* Second Dropdown - Source */}
        <Grid item xs={2.4}>
          <InputLabel sx={{ fontSize: "14px", marginBottom: "5px" }}>
            Source
          </InputLabel>
          <CommonDropdownField
           defaultValue={filterData?.source}
           onChange={handleFilterDataChange}
            // handleChange={handleIsActiveChange}
            // defaultValue={"all"}
            name="source"
            // value={formData?.stage}
            options={SOURCE_OPTIONS}
            customClass={"select_btn"}
          />
        </Grid>

        {/* Third Dropdown - Lead Owner */}
        <Grid item xs={2.4}>
          <InputLabel sx={{ fontSize: "14px", marginBottom: "5px" }}>
            Lead Owner
          </InputLabel>
          <CommonDropdownField
           defaultValue={filterData?.lead_owner}
           onChange={handleFilterDataChange}
            // handleChange={handleIsActiveChange}
            // defaultValue={"all"}
            name="lead_owner"
            // value={formData?.stage}
            options={LEAD_OWNER_OPTIONS}
            customClass={"select_btn"}
          />
        </Grid>

        {/* Fourth Dropdown - Is Active */}
        <Grid item xs={2.4}>
          <InputLabel sx={{ fontSize: "14px", marginBottom: "5px" }}>
            Is Active
          </InputLabel>

          <CommonDropdownField
              defaultValue={filterData?.is_active}
              onChange={handleFilterDataChange}
            // handleChange={handleIsActiveChange}
            // defaultValue={"all"}
            name="is_active"
            // value={formData?.stage}
            options={IS_ACTIVE_OPTIONS}
            customClass={"select_btn"}
          />
        </Grid>

        {/* Fifth Grid - Close Button */}
        <Grid item xs={2.4} sx={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            className={styles["create-btn"]}
            onClick={handleFilterReset}
            sx={{fontWeight: "400",fontSize:"12px"}}
          >
            Clear All
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterHeader;
