import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton, Badge } from "@mui/material";
import { bgBlur } from "../../../utils/cssStyles";
import Iconify from "../../../components/iconify";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import Logo from "src/components/logo";
import TitleHeader from "src/components/common/title-header";
import { useLocation } from "react-router-dom";

const NAV_WIDTH = 240;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 70;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ openNav, onOpenNav }) {
  const StyledRoot = styled(AppBar)(({ theme }) => ({
    // ...bgBlur({ color: theme.palette.background.default }),
    ...bgBlur({ color: "#F4F6F8" }),
    boxShadow: "none",
    [theme.breakpoints.up("lg")]: {
      width: openNav ? `calc(100% - ${NAV_WIDTH + 1}px)` : "100%",
    },
  }));

  const location = useLocation();
  const [, , secondSegment] = location?.pathname.split("/") || [];
  const title = secondSegment === 'app' ? 'dashboard' : secondSegment;
  return (
    <StyledRoot>
      <StyledToolbar>
        {!openNav && (
          <Box sx={{ display: "inline-flex" }}>
            <Logo openNav={openNav} />
          </Box>
        )}
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box>
          <Box sx={{ display: "flex" ,alignItems:"center"}}>
            <img
              // src={"/assets/icons/navbar/leads2.png"}
              src={`/assets/icons/navbar/ic_${secondSegment}.svg`}
              alt="cognifydigital"
              height={20}
              style={{ margin: "10px 8px 0px 0px" }}
              fill="red"
            />
            <TitleHeader title={title} />
          </Box>

          <Box
            sx={{
              display: "flex",
              // color: "black",
              fontSize: "12px",
              color: "grey",
            }}
          >
            {location?.pathname.split("/").map((item) => (
              <div style={{ textTransform: "capitalize" }}>
                {item}
                <span style={{ margin: "0px 5px 0px 5px" }}>/</span>
              </div>
            ))}
            {/* <div>Dashboard</div>
            <div style={{ margin: "0px 5px 0px 5px" }}>/</div>
            <div>Leads</div> */}
          </Box>
        </Box>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
            <AccountPopover />
          <IconButton
            onClick=""
            sx={{ width: 42, height: 42, color: "grey", padding: "8px 8px" }}
          >
            <Badge
              sx={{
                backgroundColor: "white",
                borderRadius: "100%",
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
              }}
            >
              <Iconify
                icon="mdi:settings-outline"
                sx={{
                  width: 40,
                  height: 40,
                  color: "grey",
                  padding: "8px 8px",
                }}
              />
            </Badge>
          </IconButton>

          <NotificationsPopover />

        
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
