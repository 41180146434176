import { Box } from "@mui/material";
import React from "react";
import ContactInfo from "./contact-info";
import Address from "./address";
import TopHeader from "src/pages/accounts/detail-view/components/top-header";

const Summary = ({ styles ,data}) => {
  return (
    <Box className={styles["summary-main"]}>
      <Box className={styles["first-col"]}>
        <ContactInfo styles={styles} data={data}/>
        <Box className={styles["vertical-spacer"]} />
        <Address styles={styles} />
      </Box>
    </Box>
  );
};

export default Summary;
