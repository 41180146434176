import React from "react";
import { Box, IconButton, Grid, InputBase, Paper, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import OpportunityFilters from "./contacts-filters";
import { Add, SaveAlt } from "@mui/icons-material";

const TableHeader = ({
  styles,
  openFilter,
  handleOpenFilter,
  handleCloseFilter,
  value,
  setValue,
  handleOpen,
  filterData,
  handleFilterDataChange,
  handleFilterReset,
  handleFilterSubmit,
  handleSearch,
  searchField,
  setSearchField,
  handleChange,
  query,
}) => {
  return (
    <Box sx={{ p: 2, backgroundColor: "#E6EAEE", marginBottom: "10px" }}>
      <Grid container spacing={2} alignItems="center">
        {/* First Column */}
        <Grid item xs={12} sm={5}>
          <Box className={styles["total-lead"]}>
            <Box>Total Contacts : </Box>
            <Box> 77</Box>
          </Box>
        </Grid>

        {/* Search Bar */}
        <Grid item xs={12} sm={3}>
          <Paper component="form" className={styles["search-input"]}>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              name="search"
              // defaultValue={query.search}
              // onChange={handleChange}
              placeholder="Search Contacts"
              inputProps={{ "aria-label": "search Contacts" }}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        {/* export */}
        <Grid item xs={12} sm={1} sx={{}}>
              <Button
                variant="contained"
                className={styles["button"]}
                onClick={openFilter}
                open={openFilter}
                endIcon={<SaveAlt className={styles["export_btn_icon"]} />}
              >
                <Box className={styles["export_btn"]}>Export</Box>
              </Button>
            </Grid>
        {/* Filter  */}
        <Grid item xs={12} sm={1} sx={{ ml: 2 }}>
          <OpportunityFilters
            openFilter={openFilter}
            onOpenFilter={handleOpenFilter}
            onCloseFilter={handleCloseFilter}
            value={value}
            setValue={setValue}
            filterData={filterData}
            handleFilterDataChange={handleFilterDataChange}
            handleFilterReset={handleFilterReset}
            handleFilterSubmit={handleFilterSubmit}
            styles={styles}
          />
        </Grid>

        <Grid item xs={12} sm={1} sx={{ ml: 2 }}>
          <Button
            variant="contained"
            onClick={handleOpen}
            className={styles["create-btn"]}
            // endIcon={<Add />}
          >
            <Box className={styles["export_btn"]}>Add Contact</Box>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TableHeader;
