import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTheme } from "@mui/material/styles";
import { Card, CardHeader, Typography, Box } from "@mui/material";

const styles = {
  card: {
    height: "100%",
    display: 'flex',
    justifyContent: 'center',
    padding: '8px 16px',
  },
  chartContainer: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',

    padding: '24px',
    width: '100%',
  },
  cardHeader: {
    padding: '0',
    marginBottom: '16px',
  },
  title: {
    fontSize: "13px",
    border:" 1px solid #959495 ",borderLeft: "none",borderRight: "none",borderTop: "none" ,
    fontWeight: "bold",
    color:"#959495"
  },
  legendContainer: {
    marginBottom: '6px',
    display: 'flex',

    flexDirection: 'column',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  legendMarker: {
    width: '12px',
    height: '12px',
    borderRadius: '6px',
    marginRight: '8px',
  },
  chart: {
    padding: '0',

  },
};

const ColumnChart = ({ title, chartData, category }) => {
  const adjustedChartColors = ["#379ACE", "#EF3817"]; // Blue for Closed, Red for Lost

  const options = {
    chart: {
      type: "bar",
      height: 240,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#FFFFFF'],
        fontWeight: 'bold'
      },
    },
    yaxis: {
      tickAmount: 3,
      min: 0,
      max: 15,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: category,
    },
    fill: {
      opacity: 1,
      colors: adjustedChartColors,
    },
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
  };

  return (
    <Card sx={styles.card}>
      <Box sx={styles.chartContainer}>
        <CardHeader
          title={
            <Typography sx={styles.title}>
              {title}
            </Typography>
          }
          sx={styles.cardHeader}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={styles.legendContainer}>
            <Box sx={styles.legendItem}>
              <Box
                sx={{
                  ...styles.legendMarker,
                  backgroundColor: adjustedChartColors[0],
                }}
              />
              <Typography  sx={{ fontSize:"12px" , fontWeight:500 }}>Closed</Typography>
            </Box>
            <Box sx={styles.legendItem}>
              <Box
                sx={{
                  ...styles.legendMarker,
                  backgroundColor: adjustedChartColors[1],
                }}
              />
              <Typography  sx={{ fontSize:"12px" , fontWeight:500 }}>Lost</Typography>
            </Box>
          </Box>
        </Box>

      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center",paddingTop:"5%" }}>
        <ReactApexChart
          options={options}
          series={chartData}
          type="bar"
          height={190}
          width={320}
          sx={styles.chart}
        />
      </Box>
    </Card>
  );
};

export default ColumnChart;
