import React from "react";
import { Box } from "@mui/material";
import styles from "./style.module.css";
import Header from "./components/header";
import { useState } from "react";
import Summary from "./components/summary";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContactDetails } from "src/hooks/useContactsData";
import Loader from "src/components/common/loader";
import RelatioshipAnalytics from "./relationship-analytics";
import { useEffect } from "react";

const ContactDetailView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const { id } = useParams();
  const [value, setValue] = useState("");
  const { data, isLoading } = useContactDetails({ id });
  const [currentPathValue, setCurrentPathValue] = useState(null);
  
  const getValue = (value) => {
    setValue(value);
  };


  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pathParam = params.get("path");
    setCurrentPathValue(pathParam);
  }, [location.search]);


  
  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (!params.get("path")) {
      navigate(`${location.pathname}?path=contact-summary`);
    }
  }, [location.search]); // eslint-disable-line

  return isLoading ? (
    <Loader />
  ) : (
    <Box>
      {/* <TopHeader styles={styles} /> */}
      <Box className={styles["vertical-spacer"]} />
      <Header
        styles={styles}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={data}
      />

      {/* {activeTab === 0 && <Summary styles={styles} data={data} />}
       */}
        {currentPathValue === "contact-summary" && (<Summary styles={styles} data={data} />)}
        {currentPathValue === "contact-relationship-analytics" && (<RelatioshipAnalytics styles={styles} data={data} />)}
    </Box>
  );
};

export default ContactDetailView;
