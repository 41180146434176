import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { CommonInputField } from "src/components/common/common-input-field";
import { SubmitButton } from "src/components/common/common-button";
import classNames from "classnames";
import { formatDateAndTime } from "src/utils/formatTime";
import { Edit, RemoveRedEyeOutlined } from "@mui/icons-material";

const NewStakeholderForm = ({
  styles,
  stakeholderData,
  setFormData,
  handleEditModalOpen,

  formData,
}) => {
  return stakeholderData?.contact.map((item, index) => (
    <Box className={styles["progress_card_1"]}>
      {/* {singleInfo(
        "phone",
        "Phone",
        data?.phone,
        <Box className={styles["end-icon"]}>
          <Phone fontSize="14px" />
        </Box>
      )} */}
      <form
      // onSubmit={editing ? handleUpdate : handleAdd}
      // style={{ padding: "20px" }}
      >
        <Box className={styles["analysi_main"]}>
          <Box className={styles["analysis_box"]}>
            <Box className={styles["chip_edit"]}>
              <Box
                className={classNames(
                  "info-chip",
                  styles["custom-chip"],
                  item?.is_decision_maker ? "" : "danger-chip"
                )}
              >
                {item?.is_decision_maker
                  ? "Decision Maker"
                  : "Non Decision Maker"}
              </Box>
                <Box
                  className={styles["edit_phase"]}
                  onClick={() => {
                    handleEditModalOpen();
                    setFormData({ ...item });
                  }}

                >
                  <Edit className={styles["edit_phase_icon"]} />
                </Box>
              
            </Box>

            <Box className={styles["text_field"]}>
              <CommonInputField
                placeholder="Name"
                name="name"
                // onChange={handleChange}
                value={item?.name}
                customClass={"half-width-field"}
              />
              <CommonInputField
                placeholder="Designation"
                name="designation"
                // onChange={handleChange}
                value={item?.designation}
                // customClass={styles["common-search-input-right"]}
                customClass={"half-width-field"}
              />
            </Box>
            <Box className={styles["text_field"]}>
              <CommonInputField
                placeholder="Email"
                name="email"
                // onChange={handleChange}
                value={item?.email}
                customClass={"half-width-field"}
              />
              <CommonInputField
                placeholder="Contact Number"
                name="number"
                // onChange={handleChange}
                value={item?.number}
                // customClass={styles["common-search-input-right"]}
                customClass={"half-width-field"}
              />
            </Box>

            <CommonInputField
              placeholder="Comment"
              name="comment"
              // onChange={handleChange}
              value={item?.comment}
              customClass={styles["stake-text-area"]}
              minLines={4}
            />
          </Box>
          <Box className={styles["progress_card_job"]}>
            {formatDateAndTime(item?.created_at)}
          </Box>
        </Box>
      </form>
    </Box>
  ));
};

export default NewStakeholderForm;
