import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { useCheckEmailMutation, useCreateCheckoutSessionMutation, usePostPlansMutation } from "src/hooks/useSessiondata";
import { useNavigate } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';

const tiers = [
  {
    key: "Smart_Plan",
    plan: "Smart Plan",
    subheader: "Recommended",
    price: "50",
    description: [
      "15 Days Free for 2 Logins",
      "Extra Login Available",
      "Monthly Pay Available",
      "Live Chat Support",
    ],
    buttonText: "Start now",
    buttonVariant: "contained",
  },
];

export default function Pricing() {
  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlanState] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
  });
  const checkEmailMutation = useCheckEmailMutation();
  const createCheckoutSessionMutation = useCreateCheckoutSessionMutation();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const handleOpenModal = (tier) => {
    setSelectedPlanState(tier);
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    setFormData({
      email: "",
    });
    setErrorMessage("");
  };
  const link = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  console.log(link);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const redirectToSignup = () => {
    navigate("/signup");
  };
  // const handleSubmit = () => {
  //   const email = formData.email;

  //   checkEmailMutation.mutateAsync(email, {
  //     onSuccess: (data) => {
  //       if (data.exists) {
  //         setErrorMessage(""); 
  //         handleCloseModal();
  //         window.location.href = process.env.REACT_APP_STRIPE_URL;
  //       } else {
  //         setErrorMessage("Email does not exist"); 
  //       }
  //     },
  //     onError: (error) => {
  //       console.error("Error checking email:", error);
  //     },
  //   });


  // };

  const handleSubmit = async () => {
    const email = formData.email;

    try {
      // Check if the email exists
      checkEmailMutation.mutateAsync(email, {
        onSuccess: async (data) => {

          if (data.exists) {
            setErrorMessage(''); // Clear any existing error message

            // Create a Stripe checkout session
            try {
              const sessionData = await createCheckoutSessionMutation.mutateAsync({user:data?.user_id, plan : "b203539c-f56b-4847-8103-62b250eb5f0d"});

              console.log('Checkout session created:', sessionData);

              // Initialize Stripe
              const stripe = await stripePromise;

              // Redirect to Stripe Checkout
              const { error } = await stripe.redirectToCheckout({
                sessionId: sessionData.id,
              });

              if (error) {
                console.error('Stripe checkout error:', error.message);
                alert('Failed to redirect to checkout. Please try again.');
              }
            } catch (checkoutError) {
              console.error('Error during checkout session creation:', checkoutError);
              alert('An error occurred while processing your payment. Please try again later.');
            }
          } else {
            // Email does not exist
            setErrorMessage('Email does not found please signup');
          }
        },
        onError: (error) => {
          console.error('Error checking email:', error);
          alert('An unexpected error occurred while checking the email. Please try again later.');
        }
      });
    } catch (emailError) {
      console.error('Error checking email:', emailError);
      alert('An unexpected error occurred while checking the email. Please try again later.');
    }
  };
  return (
    <Container
      id="pricing"
      sx={{
        pb: { xs: 4, sm: 8 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Pricing
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Quickly build an effective pricing table for your potential customers
          with this layout. <br />
          It&apos;s built with default Material UI components with little
          customization.
        </Typography>
      </Box>

      <Grid container sx={{ justifyContent: "center", gap: "5%", width: "100%" }} spacing={2}>
        {/* Unique Left Card */}
        <Grid item xs={10} md={4}>
          <Card
            sx={{
              backgroundColor: "#E1F5FE",
              p: 3,
              textAlign: "center",
              justifyContent: "space-between",
              borderRadius: "16px",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              position: "relative",
              overflow: "hidden",
            }}
          >
            {/* Decorative Background Element */}
            <Box
              sx={{
                position: "absolute",
                top: "-50px",
                right: "-50px",
                width: "150px",
                height: "150px",
                backgroundColor: "#4FC3F7",
                borderRadius: "50%",
                opacity: 0.3,
              }}
            ></Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "-50px",
                left: "-50px",
                width: "150px",
                height: "150px",
                backgroundColor: "#4FC3F7",
                borderRadius: "50%",
                opacity: 0.3,
              }}
            ></Box>

            <Box>
              <Box
                sx={{
                  backgroundColor: "#4FC3F7",
                  borderRadius: "50%",
                  width: 40,
                  height: 40,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: 2,
                }}
              >
                <AutoAwesomeIcon sx={{ color: "#FFF", fontSize: 24 }} />
              </Box>
              <Typography sx={{ textAlign: "left" }} variant="h5" fontWeight="bold" gutterBottom>
                Start saving <br /> your money
              </Typography>
              <Typography sx={{ textAlign: "left" }} variant="body2" color="text.secondary" paragraph>
                Choose a plan that works best for you, feel free to contact us if
                you need more details.
              </Typography>
            </Box>
            <Box
              sx={{
                mt: 3,
                p: 2,
                backgroundColor: "#01579B",
                borderRadius: "8px",
                color: "#FFF",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: 3,
              }}
            >
              <Typography variant="body1" textAlign="center">
                Free Demo
              </Typography>
              <Divider
                sx={{
                  my: 1,
                  borderColor: "rgba(255, 255, 255, 0.2)",
                  width: "100%",
                }}
              />
              <Typography variant="caption">Start your free demo today by</Typography>
              <Typography variant="subtitle2" fontWeight="bold">
                signing up
              </Typography>
            </Box>
          </Card>
        </Grid>

        {tiers.map((tier) => (
          <Grid item key={tier.key} xs={10} sm={6} md={4}>
            <Card
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                gap: 4,
                flexGrow: 1,
                border: tier.key === "Smart_Plan" ? "1px solid" : undefined,
                borderColor:
                  tier.key === "Smart_Plan" ? "primary.main" : undefined,
                background:
                  tier.key === "Smart_Plan"
                    ? "linear-gradient(#033363, #021F3B)"
                    : undefined,
                borderRadius: "16px",
                boxShadow: 3,

              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: tier.key === "Smart_Plan" ? "grey.100" : "",
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.plan}
                  </Typography>
                  {tier.key === "Smart_Plan" && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === "light" ? "" : "none",
                        backgroundColor: "primary.contrastText",
                        "& .MuiChip-label": {
                          color: "primary.dark",
                        },
                        "& .MuiChip-icon": {
                          color: "primary.dark",
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    color:
                      tier.key === "Smart_Plan" ? "grey.50" : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    ${tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; per month
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: "grey.500",
                  }}
                />
                {tier.description.map((line, index) => (
                  <Box
                    key={index}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.key === "Smart_Plan"
                            ? "primary.light"
                            : "primary.main",
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.key === "Smart_Plan"
                            ? "grey.200"
                            : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  component="a"
                  href="#"
                  onClick={() => handleOpenModal(tier)}
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={open} onClose={handleCloseModal} fullWidth>
        <DialogTitle>Plan Details</DialogTitle>
        <DialogContent>
          {selectedPlan && (
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                {selectedPlan.plan}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Price: ${selectedPlan.price} per month
              </Typography>
              <Typography variant="body2" gutterBottom>
                {selectedPlan.description.join(", ")}
              </Typography>
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                fullWidth
                sx={{ mt: 2 }}
                error={!!errorMessage}
                helperText={errorMessage}
              />

            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          {errorMessage ? <Button onClick={redirectToSignup} variant="contained">
            signup
          </Button> : <Button onClick={handleSubmit} variant="contained">
            Subscribe
          </Button>}
        </DialogActions>
      </Dialog>
    </Container>
  );
}
