import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useState } from "react";
import Summary from "./components/deals_summary";
import Notes from "./components/notes";
import Attachment from "./components/attachment";
import styles from "./style.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Loader from "src/components/common/loader";
import { useQueryClient } from "@tanstack/react-query";
import { showNotification } from "src/components/notification";
import ConvertDialog from "./components/convert-dialog";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Header from "./components/header";
import {
  useDealsAttachmentMutation,
  useDealsDetails,
  useDealsNotesEditMutation,
  useDealsUpdateMutation,
  useDeleteDealsAttachmentMutation,
  useDeleteDealsNoteMutation,
} from "src/hooks/useDealsData";
import DealsKeyDetails from "./components/deals-key-details";

const DealsDetailView = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, isLoading } = useDealsDetails({ id });
  const [value, setValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPathValue, setCurrentPathValue] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [editData, setEditData] = useState({ editing: false });
  const [isSummaryEditing, setIsSummaryEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [conversionFormData, setConversionFormData] = useState({});

  const attachmentMenuOpen = Boolean(anchorEl?.attachment);
  const [editedData, setEditedData] = useState(data);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pathParam = params.get("path");
    setCurrentPathValue(pathParam);
  }, [location.search]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (!params.get("path")) {
      navigate(`${location.pathname}?path=deals-summary`);
    }
  }, [location.search]); // eslint-disable-line

  useEffect(() => {
    const removeStatusBar = () => {
      const statusBarElement = document.querySelector(".jodit-status-bar");
      if (statusBarElement) {
        statusBarElement.remove();
      } else {
        // If element is not found, try again after a short delay
        setTimeout(removeStatusBar, 100);
      }
    };

    removeStatusBar();
  }, []);

  const editNotesMutation = useDealsNotesEditMutation();
  const leadsMutation = useDealsAttachmentMutation();
  const deleteLeadAttachmentMutation = useDeleteDealsAttachmentMutation();
  const deleteLeadNoteMutation = useDeleteDealsNoteMutation();
  const leadsUpdateMutation = useDealsUpdateMutation();
  const queryClient = useQueryClient();

  const getValue = (value) => {
    setValue(value);
  };

  const handleOpenMenu = (event, tab) => {
    if (tab === "attachment") {
      setAnchorEl({ ...anchorEl, attachment: event.currentTarget });
    } else if (tab === "header") {
      setAnchorEl({ ...anchorEl, header: event.currentTarget });
    }
  };

  const handleCloseMenu = (tab) => {
    if (tab === "attachment") {
      setAnchorEl({ ...anchorEl, attachment: null });
    } else if (tab === "header") {
      setAnchorEl({ ...anchorEl, header: null });
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (data) setValue(data?.description);
  }, [data]);

  const handleEditNotes = async (e, notes_id) => {
    e.preventDefault();
    try {
      await editNotesMutation.mutateAsync(
        {
          lead_id: id,
          notes_id: notes_id,
          description: editData?.message?.description,
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["leadDetail"] });
            showNotification("success", "Notes Updated Successfully.!", 5000);
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
    setEditData({ ...editData, editing: false });
  };

  const handleEdit = (field, value) => {
    setEditedData({ ...editedData, [field]: value });
  };

  const handleFileUpload = async (file) => {
    var formData = new FormData();
    formData.append("document_file", file);
    formData.append("title", "Title");
    formData.append("description", "Description");
    try {
      await leadsMutation.mutateAsync(
        { data: formData, id: data?.lead_detail?.id },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["leadDetail"] });
            showNotification(
              "success",
              "Attachments Added Successfully.!",
              2000
            );
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
  };

  const handleDeleteAttachment = async (e) => {
    e.preventDefault();
    try {
      await deleteLeadAttachmentMutation.mutateAsync(
        { lead_id: id, attachment_id: deleteId },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["leadDetail"] });
            showNotification(
              "success",
              "Attachment Deleted Successfully.!",
              5000
            );
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
    setDeleteId(null);
  };

  const handleDeleteNotes = async (e, notes_id) => {
    e.preventDefault();
    try {
      await deleteLeadNoteMutation.mutateAsync(
        { lead_id: id, note_id: notes_id },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["leadDetail"] });
            showNotification("success", "Notes Deleted Successfully.!", 5000);
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
    setDeleteId(null);
  };

  const handleSummarySave = async () => {
    try {
      await leadsUpdateMutation.mutateAsync(
        { ...editedData, description: value, id: id },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["leadDetail"] });
            showNotification("success", "Summary Updated Successfully.!", 5000);
          },
        }
      );
    } catch (error) {
      showNotification("error", error, 8000);
    }
    setIsSummaryEditing(false);
  };

  const handleOpportunityConversion = () => {
    setIsOpen(false);
  };

  const handleConversionFormDataChange = (e) => {
    e.preventDefault();
    setConversionFormData({ ...editedData, [e.target.name]: e.target.value });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Box>
      <Header
        styles={styles}
        activeTab={selectedTab}
        setActiveTab={setSelectedTab}
        data={data}
        setIsSummaryEditing={setIsSummaryEditing}
        isSummaryEditing={isSummaryEditing}
        editedData={editedData}
        handleEdit={handleEdit}
        setEditedData={setEditedData}
        handleSave={handleSummarySave}
        handleConvertOpen={() => setIsOpen(true)}
        anchorEl={anchorEl?.header || null}
        handleOpenMenu={handleOpenMenu}
        handleCloseMenu={handleCloseMenu}
      />
      <DealsKeyDetails styles={styles} data={data} />
      {currentPathValue === "deals-summary" && (
        <>
          <Box className={styles["vertical-spacer"]} />
          <Summary
            styles={styles}
            data={data}
            getValue={getValue}
            value={value}
            isSummaryEditing={isSummaryEditing}
            setIsSummaryEditing={setIsSummaryEditing}
            handleSave={handleSummarySave}
            handleChange={handleEdit}
          />
        </>
      )}
      {currentPathValue === "opportunity-notes" && (
        <Notes
          id={id}
          styles={styles}
          data={data}
          handleDeleteNotes={handleDeleteNotes}
          editData={editData}
          setEditData={setEditData}
          handleEditNotes={handleEditNotes}
        />
      )}
      {currentPathValue === "opportunity-attachment" && (
        <Attachment
          styles={styles}
          data={data}
          handleFileUpload={handleFileUpload}
          anchorEl={anchorEl?.attachment || null}
          handleOpenMenu={handleOpenMenu}
          handleCloseMenu={handleCloseMenu}
          open={attachmentMenuOpen}
          handleDeleteAttachment={handleDeleteAttachment}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
        />
      )}

      <ConvertDialog
        isOpen={isOpen}
        data={data}
        conversionFormData={conversionFormData}
        handleChange={handleConversionFormDataChange}
        openDialog={() => setIsOpen(true)}
        closeDialog={() => setIsOpen(false)}
        handleSubmit={handleOpportunityConversion}
      />
      <ToastContainer />
    </Box>
  );
};

export default DealsDetailView;
