import { Edit } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { CommonInputField } from "src/components/common/common-input-field";
import { formatDateAndTime } from "src/utils/formatTime";

const ContactInfo = ({ styles, data }) => {
  // const singleInfo = (name, placeholder, value,) => {
  //   return (
  //     <Box className={styles["single-info"]}>
  //       <Box className={styles["title"]}>
  //         <Typography className={styles["title-text"]}> {placeholder}</Typography>
  //       </Box>
  //       <Box className={styles["input-box"]}>
  //         <CommonInputField
  //           name={name}
  //           value={value}
  //           handleChange={() => {}}
  //           placeholder={placeholder}
  //           customClass={styles["common-input-div"]}
  //           readOnly={true}
  //         />
  //       </Box>
  //     </Box>
  //   );
  // };
  return (
    // <Box
    //   className={classNames("common-box-shadow", styles["account-info-card"])}
    // >
    //   <Typography className={styles["heading"]}>Contact Information</Typography>
    //   {singleInfo("account_name", "Account Name", data?.name)}
    //   {singleInfo("phone", "Phone", data?.number)}
    //   {singleInfo("email", "Email", data?.email)}
    //   {singleInfo("designation", "Designation", data?.designation)}
    //   {singleInfo("decision_maker", "Decision Maker", data?.is_decision_maker ? "Yes" : "No")}
    // </Box>
    <>
      <Box className={styles["analysi_main"]}>
        <Box className={styles["analysis_box"]}>
          <Box className={styles["chip_edit"]}>
            <Box className={styles["custom-chip2"]}>Contact 1</Box>
            <Box
              className={styles["edit_phase"]}
              // onClick={() => {
              //   handleEditModalOpen();
              //   setFormData({ ...item });
              // }}
            >
              <Edit className={styles["edit_phase_icon"]} />
            </Box>
          </Box>

          <Box className={styles["text_field"]}>
            <CommonInputField
              placeholder="Name"
              name="name"
              // onChange={handleChange}
              value={data?.name}
              customClass={"half-width-field"}
            />
            <CommonInputField
              placeholder="Designation"
              name="designation"
              // onChange={handleChange}
              value={data?.designation}
              // customClass={styles["common-search-input-right"]}
              customClass={"half-width-field"}
            />
          </Box>
          <Box className={styles["text_field"]}>
            <CommonInputField
              placeholder="Email"
              name="email"
              // onChange={handleChange}
              value={data?.email}
              customClass={"half-width-field"}
            />
            <CommonInputField
              placeholder="Contact Number"
              name="number"
              // onChange={handleChange}
              value={data?.number}
              // customClass={styles["common-search-input-right"]}
              customClass={"half-width-field"}
            />
          </Box>

          <CommonInputField
            placeholder="Comment"
            name="comment"
            // onChange={handleChange}
            value={data?.comment}
            customClass={styles["stake-text-area"]}
            minLines={4}
          />
        </Box>
        <Box className={styles["progress_card_job"]}>
          {formatDateAndTime(data?.created_at)}
        </Box>
      </Box>
    </>
  );
};

export default ContactInfo;
