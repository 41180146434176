import PropTypes from "prop-types";
import { Box, Drawer } from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
import navConfig from "./config";
import { NavItem } from "src/components/nav-section/NavSection";
import SvgColor from "../../../components/svg-color";

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1, color: "#212A3B" }}
  />
);

const NAV_WIDTH = 240;

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav, onOpenNav }) {
  const isDesktop = useResponsive("up", "lg");
  // const user = {
  //   title: "User Management",
  //   path: "/dashboard/users",
  //   icon: icon("user_svg"),
  // };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          height: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
            <Logo openNav={openNav} onOpenNav={onOpenNav} />
          </Box>
          <NavSection data={navConfig} />
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ px: 1, py: 3, display: "inline-flex" }}>
          {/* <NavItem item={user} /> */}
        </Box>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: openNav ? NAV_WIDTH : 0 },
      }}
    >
      {isDesktop ? (
        <Drawer
          open={openNav}
          variant="permanent"
          PaperProps={{
            sx: {
              width: openNav ? NAV_WIDTH : 0,
              bgcolor: "background.default",
              backgroundColor: "#F4F6F8",
              // borderRightStyle: "dashed",
              border: "none !important",
              boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
