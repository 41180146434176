import React, { useEffect, useState } from "react";
import { Box, Divider, Typography, MenuItem, Select } from "@mui/material";
import { formatDateAndTime } from "src/utils/formatTime";
import PersonIcon from "@mui/icons-material/Person";
import DateRangeIcon from "@mui/icons-material/DateRange";
import classNames from "classnames";
import {
  Casino,
  ContactPage,
  Paid,
  Troubleshoot,
  CheckCircle,
} from "@mui/icons-material";
import ProgressStepper from "./lead-progress";
import LeadAnalysisPhase from "./analysis-phase";
import IdentifyStakeHolderPhase from "./stakeholder/identify-stakeholder-phase";
import ProposalPhase from "./proposal/proposal-phase";
import ClosePhase from "./close-lead/close-phase";
import IdentifyStakeholderModal from "./stakeholder/new-stakeholder-modal";
import SendProposalModal from "./proposal/send-proposal-modal";
import CloseWonModal from "./close-lead/close-won-modal";
import CloseLostModal from "./close-lead/close-lost-modal";
import {
  useLeadsProposalActivityData,
  useLeadsStakeholderActivityData,
  usePostMarkAsCompletedProposalMutation,
} from "src/hooks/useLeadsData";
import { useParams } from "react-router-dom";
import { showNotification } from "src/components/notification";
import { useQueryClient } from "@tanstack/react-query";
import NewStakeholderForm from "./stakeholder/new-stakeholder-form";

const Summary = ({
  styles,
  data,
  value,
  getValue,
  isSummaryEditing,
  editedData,
  handleChange,
}) => {
  const { id } = useParams();
  // React Query Mutations
  const queryClient = useQueryClient();
  const {
    stakeholderData,
    stakeholderLoading,
  } = useLeadsStakeholderActivityData({ id });
  const { proposalData, proposalLoading } = useLeadsProposalActivityData({
    id,
  });
  const useMarkCompleteProposalMutation = usePostMarkAsCompletedProposalMutation();

  // States
  const [currentAction, setCurrentAction] = useState(2);
  const [openModal, setOpenModal] = useState(null);
  const [proposalFile, setProposalFile] = useState([]);
  const [formData, setFormData] = useState();
  const [editing, setEditing] = useState(false);

  const [proposalsData, setProposalsData] = useState(proposalData);
  const [stepperData, setStepperData] = useState([]);

  // Data re-rendering logic
  useEffect(() => {
    setProposalsData(proposalData);

    // Calculate the isCompleted status based on the majority of proposalData
    const completedProposals =
      proposalData?.filter((proposal) => proposal.is_completed) || [];
    const isProposalCompleted =
      completedProposals.length > (proposalData?.length || 0) / 2;

    const progressData = [
      {
        label: "Create Lead",
        sequence: 1,
        isCompleted: true,
        tooltip: "This phase is completed automatically when you create a lead",
      },
      {
        label: "Analyse Requirement",
        sequence: 2,
        isCompleted: Boolean(data?.purchase_timeline && data?.description),
        tooltip:
          "Examine the client's requirements to ensure a tailored solution can be provided",
      },
      {
        label: "Identify Stakeholders",
        sequence: 3,
        isCompleted: Boolean(stakeholderData?.is_completed),
        tooltip:
          "Identify key decision-makers and influencers in the client's organization",
      },
      {
        label: "Proposal & Demo",
        sequence: 4,
        isCompleted: isProposalCompleted,
        tooltip:
          "Prepare and present a proposal along with a demonstration of the solution",
      },
      {
        label: "Close",
        sequence: 5,
        isCompleted: data?.status === "won" || data?.status === "lost",
        tooltip: "Finalize the agreement and close the deal",
      },
    ];

    setStepperData(progressData);

    // Determine current action based on the completed status of each phase
    const currentAction =
      data?.status === "won" || data?.status === "lost"
        ? 5
        : isProposalCompleted
        ? 5
        : stakeholderData?.is_completed
        ? 4
        : data?.description && data?.purchase_timeline
        ? 3
        : 2;

    setCurrentAction(currentAction);
  }, [data, stakeholderData, proposalData]); // eslint-disable-line

  useEffect(() => {
    const removeJoditStatusBar = () => {
      const interval = setInterval(() => {
        const joditStatusBar = document.querySelector(".jodit-status-bar");
        if (joditStatusBar) {
          joditStatusBar.remove();
          clearInterval(interval);
        }
      }, 1000);
    };

    removeJoditStatusBar();

    return () => {};
  }, []);

  // Essential Functions
  const handleModalOpen = (modelName) => {
    setOpenModal({ [modelName]: true });
  };

  const handleModalEditOpen = (modalName) => {
    handleModalOpen(modalName);
    setEditing(true);
  };

  const handleModalClose = () => {
    setOpenModal(null);
    setTimeout(() => {
      setEditing(false);
      setFormData({});
    }, 500);
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setProposalFile(selectedFiles);
  };

  const handleMarkAsCompleted = async (type) => {
    if (type === "proposal") {
      // useMarkCompleteProposalMutation
      try {
        await useMarkCompleteProposalMutation.mutateAsync(
          { id: id },
          {
            onSuccess: (data) => {
              queryClient.invalidateQueries({ queryKey: ["proposals"] });
              showNotification(
                "success",
                "Proposal Added Successfully.!",
                2000
              );
              setCurrentAction(currentAction + 1);
            },
          }
        );
      } catch (error) {
        showNotification("error", error, 8000);
      }
    }
  };

  const getButton = (item) => {
    var returnElement = <></>;
    if (!item) {
      return "";
    }

    // if (item.label === "Identify Stakeholders") {
    //   returnElement = (
    //     <Select
    //       value={"default"}
    //       variant={"outlined"}
    //       className={classNames("common-dropdown", styles["add_new_dropdown"])}
    //     >
    //       <MenuItem value="default" disabled>
    //         Add New Stakeholder
    //       </MenuItem>
    //       <MenuItem onClick={() => handleModalOpen("identifyStakeholderModal")}>
    //         New Stakeholder
    //       </MenuItem>
    //       <MenuItem onClick={() => setCurrentAction(currentAction + 1)}>
    //         Mark As Completed
    //       </MenuItem>
    //     </Select>
    //   );
    // } else if (item.label === "Proposal & Demo") {
    //   returnElement = (
    //     <Select
    //       value={"default"}
    //       variant={"outlined"}
    //       className={classNames("common-dropdown", styles["add_new_dropdown"])}
    //     >
    //       <MenuItem value="default" disabled>
    //         Send Proposal
    //       </MenuItem>
    //       <MenuItem onClick={() => handleModalOpen("sendProposalModal")}>
    //         Send Proposal
    //       </MenuItem>
    //       {!stepperData[3]?.isCompleted && (
    //         <MenuItem
    //           onClick={() => {
    //             handleMarkAsCompleted("proposal");
    //           }}
    //         >
    //           Mark As Completed
    //         </MenuItem>
    //       )}
    //     </Select>
    //   );
    // } else if (item.label === "Close") {
    //   returnElement =
    //     data?.status === "won" || data?.status === "lost" ? (
    //       <></>
    //     ) : (
    //       <Select
    //         value={"default"}
    //         variant={"outlined"}
    //         className={classNames(
    //           "common-dropdown",
    //           styles["add_new_dropdown"]
    //         )}
    //       >
    //         <MenuItem value="default" disabled>
    //           Close the Lead
    //         </MenuItem>
    //         <MenuItem onClick={() => handleModalOpen("closeWonModal")}>
    //           Close as Won
    //         </MenuItem>
    //         <MenuItem onClick={() => handleModalOpen("closeLostModal")}>
    //           Close as Lost
    //         </MenuItem>
    //       </Select>
    //     );
    // }
    if (item.isCompleted) {
      return (
        <Box className={styles["completed-one"]}>
          {returnElement}
          <CheckCircle style={{ color: "green" }} variant="outlined" />
        </Box>
      );
    }
    return returnElement;
  };

  const handleFormDataChange = (e, type) => {
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.checked,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
    console.log(formData);
  };

  return (
    <>
      <Box>
        <Box className={styles["first-box-summary"]}>
          <Box className={styles["left-side"]}>
            <ProgressStepper
              styles={styles}
              currentAction={currentAction}
              setCurrentAction={setCurrentAction}
              data={stepperData}
              closeLeadData={data?.status === "won" || data?.status === "lost"}
              stakeholderData={stakeholderData}
              analysis={data?.description && data?.purchase_timeline}
            />
            <Box className={styles["main"]}>
              <Box
                className={classNames(
                  "common-box-shadow",
                  styles["summary_box"]
                )}
              >
                <Box className="row">
                  <Box className={styles["summary-header"]}>
                    <Typography variant="h5">
                      {
                        stepperData.find((i) => i.sequence === currentAction)
                          ?.label
                      }
                    </Typography>
                    {getButton(
                      stepperData.find((i) => i.sequence === currentAction)
                    )}
                  </Box>
                  <Divider />
                  {currentAction === 2 ? (
                    <LeadAnalysisPhase
                      styles={styles}
                      editedData={editedData}
                      handleChange={handleChange}
                      value={value}
                      getValue={getValue}
                      isSummaryEditing={isSummaryEditing}
                    />
                  ) : currentAction === 3 ? (
                    stakeholderLoading ? (
                      <>Loading</>
                    ) : (
                      <IdentifyStakeHolderPhase
                        styles={styles}
                        stakeholderData={stakeholderData}
                        setFormData={setFormData}
                        formData={formData}
                        handleModalOpen={() =>
                          handleModalOpen("identifyStakeholderModal")
                        }
                        handleEditModalOpen={() =>
                          handleModalEditOpen("identifyStakeholderModal")
                        }
                        setCurrentAction={() =>
                          setCurrentAction(currentAction + 1)
                        }
                      />
                    )
                  ) : currentAction === 4 ? (
                    proposalLoading ? (
                      <>Loading</>
                    ) : (
                      <ProposalPhase
                        styles={styles}
                        setEditing={setEditing}
                        // handleModalOpen={handleModalOpen}
                        handleModalOpen={() =>
                          handleModalOpen("sendProposalModal")
                        }
                        handleMarkAsCompleted={() => {
                          handleMarkAsCompleted("proposal");
                        }}
                        handleEditModalOpen={() =>
                          handleModalEditOpen("sendProposalModal")
                        }
                        proposalsData={proposalsData}
                        setProposalFormData={setFormData}
                      />
                    )
                  ) : (
                    <ClosePhase
                      styles={styles}
                      data={data}
                      stakeholderData={stakeholderData}
                      closeLeadData={
                        data?.status === "won" || data?.status === "lost"
                      }
                      proposalsData={proposalsData}
                      setCurrentAction={setCurrentAction}
                      setFormData={setFormData}
                      handleWonModalOpen= {() => handleModalOpen("closeWonModal")}
                      handlecloseLostModalOpen=  {() => handleModalOpen("closeLostModal")}
                      openEditCloseLeadModal={() =>
                        handleModalEditOpen("closeWonModal")
                      }
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Box className={styles["skills_box"]}>
            <Box
              className={classNames(
                "common-box-shadow",
                styles["info_box_one"]
              )}
            >
              <Box className={styles["summary-header"]}>Lead Information</Box>

              <Box className={styles["opportunity_box_one"]}>
                <Box className={styles["opportunity_box"]}>
                  <Paid className={styles["opportunity_icon"]} />
                </Box>
                <Box className={styles["text_box"]}>
                  <Box className={styles["amount_text"]}> Initial Amount:</Box>
                  <Box className={styles["amount_value"]}>
                    INR {data?.opportunity_amount}{" "}
                  </Box>
                </Box>
              </Box>

              <Box className={styles["opportunity_box_one"]}>
                <Box className={styles["opportunity_box"]}>
                  <Casino className={styles["opportunity_icon"]} />
                </Box>
                <Box className={styles["text_box"]}>
                  <Box className={styles["amount_text"]}>Probability:</Box>
                  <Box className={styles["amount_value"]}>
                    {data?.probability + "%" || "----"}
                  </Box>
                </Box>
              </Box>

              <Box className={styles["opportunity_box_one"]}>
                <Box className={styles["opportunity_box"]}>
                  <ContactPage className={styles["opportunity_icon"]} />
                </Box>
                <Box className={styles["text_box"]}>
                  <Box className={styles["amount_text"]}>Source: </Box>
                  <Box className={styles["amount_value"]}>
                    {data?.source || "----"}
                  </Box>
                </Box>
              </Box>

              <Box className={styles["opportunity_box_one"]}>
                <Box className={styles["opportunity_box"]}>
                  <Troubleshoot className={styles["opportunity_icon"]} />
                </Box>
                <Box className={styles["text_box"]}>
                  <Box className={styles["amount_text"]}>Status: </Box>
                  <Box className={data?.status && "info-chip"}>
                    {data?.status || "----"}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className={classNames(
                "common-box-shadow",
                styles["gen_info_box"]
              )}
            >
              <Box className={styles["summary-header"]}>
                General Information
              </Box>
              <Box className={styles["gen-info"]}>
                <Box className={styles["opportunity_box_one"]}>
                  <Box className={styles["opportunity_box"]}>
                    <DateRangeIcon className={styles["opportunity_icon"]} />
                  </Box>
                  <Box className={styles["text_box"]}>
                    <Box className={styles["amount_text"]}> Created Date:</Box>
                    <Box className={styles["amount_value"]}>
                      {formatDateAndTime(data?.created_at)}
                    </Box>
                  </Box>
                </Box>
                <Box className={styles["opportunity_box_one"]}>
                  <Box className={styles["opportunity_box"]}>
                    <PersonIcon className={styles["opportunity_icon"]} />
                  </Box>
                  <Box className={styles["text_box"]}>
                    <Box className={styles["amount_text"]}> Created By:</Box>
                    <Box className={styles["amount_value"]}>
                      {data?.created_by?.fullname}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box> */}
        </Box>
      </Box>

      <IdentifyStakeholderModal
        styles={styles}
        open={openModal?.identifyStakeholderModal || false}
        handleClose={handleModalClose}
        editing={editing}
        formData={formData}
        handleChange={handleFormDataChange}
      />
      {/* <NewStakeholderForm
        styles={styles}
        open={openModal?.identifyStakeholderModal || false}
        handleClose={handleModalClose}
        editing={editing}
        formData={formData}
        handleChange={handleFormDataChange}
        stakeholderData={stakeholderData}
        setFormData={setFormData}
        handleEditModalOpen={handleModalOpen}
      /> */}

      <SendProposalModal
        styles={styles}
        open={openModal?.sendProposalModal || false}
        proposalFile={proposalFile}
        handleFileChange={handleFileChange}
        handleClose={handleModalClose}
        formData={formData}
        handleChange={handleFormDataChange}
        editing={editing}
      />

      <CloseWonModal
        styles={styles}
        editing={editing}
        open={openModal?.closeWonModal || false}
        handleClose={handleModalClose}
        stepperData={stepperData}
        formData={formData}
        handleChange={handleFormDataChange}
      />

      <CloseLostModal
        styles={styles}
        open={openModal?.closeLostModal || false}
        handleClose={handleModalClose}
        closeLeadData={data?.status === "won" || data?.status === "lost"}
      />
    </>
  );
};

export default Summary;
