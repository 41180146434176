import React from "react";
import { Box, Grid } from "@mui/material";
import ProposalCard from "./proposal-card";
import { SubmitButton } from "src/components/common/common-button";
import ProposalNewCard from "./proposal-card-new";

const ProposalPhase = ({
  styles,
  proposalFile,
  proposalsData,
  setProposalFormData,
  setEditing,
  handleModalOpen,
  handleMarkAsCompleted,
  handleEditModalOpen,
}) => {
   const Buttons = () => (
       <Box className={styles["propsal_btn_main"]}>
              <Box className={styles["btn_common"]}>
                <SubmitButton
                  customClass={styles.fullWidthButton}
                  fullWidth={true}
                  text={"Send Proposal"}
                  handleClick={handleModalOpen}
                />
              </Box>
              <Box>
                <SubmitButton
                  customClass={styles.fullWidthButton}
                  fullWidth={true}
                  text={"Mark as Complete"}
                  handleClick={handleMarkAsCompleted}
                />
              </Box>
            </Box>
   );
  return (
    <Box className={styles["purposal_main_"]}>
      {proposalsData?.length === 0 && (
        <Box className={styles["analysis_box_main"]}>
          <Box className={styles["analysis_box_main"]}>
            Please Send proposal By Clicking On Send Proposal On Dropdown Menu
          </Box>
              {/* <Buttons/> */}
        </Box>
      )}
      <Box className={styles["purposal_main"]}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              {proposalsData?.map((proposalData, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}>
                  
                  <ProposalNewCard
                    styles={styles}
                    data={proposalData}
                    proposalFile={proposalFile}
                    index={index}
                    setProposalFormData={setProposalFormData}
                    handleEditModalOpen={handleEditModalOpen}
                    proposalData={proposalData}
                    handleClick={() => {
                      setProposalFormData({ ...proposalData });
                      setEditing(true);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
         <Buttons/>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProposalPhase;
